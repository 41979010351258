<template>
  <div class="news-detail">
    <div class="title gn-full-x gn-flex-center">{{topTitle}}</div>
    <!-- <div class="tabs">
      <div :class="advisory_type === '1' ? 'active' : ''">公司新闻</div>
      <div :class="advisory_type === '3' ? 'active' : ''">市场巡展</div>
    </div> -->
    <div class="title news-title gn-box-border gn-full-x gn-flex-center">{{detail.title}}</div>
    <div class="times">{{ detail.create_time }}</div>
    <div style="text-align: center;" v-html="content"></div>

    <div class="divider"></div>

    <div class="other-news">
      <div class="title gn-full-x">相关新闻</div>
      <div class="new gn-box-border">
        <a-row>
          <a-col
            :span="8"
            style="margin-bottom: 32px"
            v-for="(item, index) in otherNewList"
            :key='index'
            @click="changeContent(item)"
          >
            <a-card hoverable :bordered='false' class="card" style="width: 360px; background: #f8f8f8;">
              <div class="gn-full" style="height: 283px">
                <img
                  class="gn-full"
                  :src="item.title_image"
                />
              </div>
              <a-card-meta :title="item.title" style="padding: 25px 18px">
                <template slot="description">
                  <span class="time">{{item.create_time}}</span>
                </template>
              </a-card-meta>
            </a-card>
          </a-col>
        </a-row>
      </div>
    </div>
    <div class="more gn-full-x gn-flex-center">
      <div class="btn gn-flex-center" @click="lookMore">查看更多</div>
    </div>
  </div>
</template>

<script>
import { getArticleList } from '@/api/admin'

export default {
  metaInfo () {
    return {
      title: this.$route.query.title
    }
  },
  data () {
    return {
      reqParams: {
        page: 1,
        per_page: 9,
        search_data: '', // 关键字搜索
        advisory_type: '1', // 类型 1 公司新闻 2 行业动态 3 视频展示
        status: '', // 0 正常 1 停用 2 删除
        start_time: '', //  开始时间
        end_time: '', // 结束时间
        is_platform: '1'
      },
      content: '',
      otherNewList: [],
      name: '',
      detail: {},
      advisory_type: '',
      title: ''
    }
  },
  created () {
    const { type, title } = this.$route.query

    this.advisory_type = type
    this.title = title

    this.getData()
    this.getOtherNews()
  },
  computed: {
    topTitle () {
      let text = ''

      if (this.advisory_type === '1') { // 公司新闻
        text = '公司动态'
      } else if (this.advisory_type === '2') { // 行业动态
        text = '公司动态'
      } else if (this.advisory_type === '3') { // 视频展示
        text = '公司动态'
      }

      return text
    }
  },
  methods: {
    async getData () {
      this.reqParams.search_data = this.title
      this.reqParams.advisory_type = this.advisory_type

      const { list } = await getArticleList(this.reqParams)
      this.detail = (list || [])[0]
      this.content = (list || [])[0].content
    },
    async getOtherNews () {
      this.reqParams.advisory_type = this.advisory_type
      this.reqParams.search_data = ''
      const { list } = await getArticleList(this.reqParams)
      list.length >= 3 && (list.length = 3)
      this.otherNewList = list
    },
    async changeContent (item) {
      this.reqParams.search_data = item.title
      this.reqParams.advisory_type = item.type

      const { list } = await getArticleList(this.reqParams)
      this.detail = (list || [])[0]
      this.content = (list || [])[0].content

      window.scrollTo(0, 0)

      // 此段代码的目的仅仅是为了切换浏览器地址栏的参数信息
      this.$router.push({
        name: 'newsDetail',
        query: {
          type: item.type,
          title: item.title
        }
      })
    },
    lookMore () {
      this.$router.push({
        name: 'news',
        query: {
          type: (+this.advisory_type - 1).toString()
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .ant-card-meta-title {
    font-weight: 700;
    font-size: 18px;
    color: #333333;
  }
  .ant-card-body{
    padding: 0;
  }
}
</style>

<style lang='scss' scoped>
.news-detail{
  margin-top: 68px;
  padding: 45px 370px;
  .title{
    font-size: 48px;
    font-weight: 700;
    color: #333334;
    margin-bottom: 40px;
  }
  .tabs{
    display: flex;
    justify-content: center;
    width: 100%;

    div{
      padding-bottom: 20px;
      box-sizing: border-box;
      font-size: 24px;
      font-family: Source Han Sans CN, Source Han Sans CN-Bold;
      text-align: justifyLeft;
      color: #333334;
      line-height: 51px;
    }
    div:nth-child(1){
      margin-right: 60px;
    }
    .active{
      font-weight: 700;
      border-bottom: 2px solid #00a0ac;
    }
  }
  .times{
    margin-bottom: 50px;
    text-align: center;
  }
  .news-title{
    font-size: 30px;
    border-top: 1px solid #ccc;
    padding-top: 50px;
  }
  .divider{
    height: 0;
    border: 1px solid #cccccc;
    margin: 84px 0;
    margin-bottom: 40px;
  }
  .other-news{
    .title{
      font-size: 34px;
      font-weight: 700;
      padding: 20px 0px 14px 0px;
      color: #333333;
      width: 140px;
      border-bottom: 2px solid #00A0AC;
      box-sizing: border-box;
      margin: 0 auto;
      margin-bottom: 20px;
    }
    .new {
      padding: 42px 0;
      .card {
        .time {
          font-size: 15px;
          color: #333333;
          display: inline-block;
          margin-top: 10px;
        }
      }
    }
  }
  .more{
    .btn{
      width: 174px;
      height: 43px;
      border: 1px solid #333333;
      font-size: 18px;
      color: #333333;
      cursor: pointer;
    }
  }
}
</style>
